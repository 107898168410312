<!-- Created by henian.xu on 2017/10/1. -->

<template>
  <Page>
    <container
      class="bc-g1"
      ref="container"
    >
      <div class="prepaid-card">
        <div class="img-obj">
          <div
            class="inner"
            :style="`background-image: url('${cardData.cardLogoUrl || $defaultImg}');`"
          />
        </div>
        <!--<div class="body">
                    <div class="label">{{cardData.cardName}}</div>
                    <div class="desc" v-if="cardData.cardDesc">{{cardData.cardDesc}}</div>
                </div>-->
        <div class="worth">
          <!--<div class="value">
                        <div class="badge badge-second fs-small">面值</div>
                        <div class="label">${{cardData.cardValue | price}}</div>
                    </div>-->
          <div class="value">
            <div class="price fs-more">
              {{ cardData.cardValue | price }}
            </div>
            <div class="badge badge-second fs-small">
              面值
            </div>
          </div>
          <div class="value">
            <div class="price fs-more">
              {{ cardData.cardPrice | price }}
            </div>
            <div class="badge badge-second fs-small">
              售卖价格
            </div>
          </div>
        </div>
      </div>
      <List class="pay-list ma-t">
        <ListItem label="请选择支付方式" />
        <PrepaidPayPopup
          ref="prepaidPayPopup"
          :order-ids="orderData.id"
          :module="2"
        />
      </List>
    </container>
  </Page>
</template>

<script>
import { Buyer } from '@/api';

export default {
  name: 'PrepaidNew',
  data() {
    return {
      cardData: {},
      orderData: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    const { cardId } = to.params;
    Promise.all([
      Buyer.Mb.PrepaidCard.createBuyerPrepaidOrder(cardId),
      Buyer.Mb.PrepaidCard.get(cardId),
    ])
      .then(jsons => {
        const orderData = jsons[0].data.data;
        const cardData = jsons[1].data.data;
        next(vm => {
          vm.orderData = orderData;
          vm.cardData = cardData;
        });
      })
      .catch(() => next());
  },
};
</script>


<style lang="scss" scoped>
.visa-icon {
  width: 0.8rem;
  padding: $padding 0;
  > img {
    width: 100%;
    height: auto;
  }
}
</style>

<style lang="scss">
.prepaid-card {
  padding: $padding-big * 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  > .img-obj {
    flex: 0 0 auto;
    width: 80%;
    > .inner {
      width: 100%;
      height: 0;
      padding-top: 45%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0 2px 10px 2px rgba(60, 60, 60, 0.2);
      border-radius: 0.1rem;
    }
  }
  > .body {
    flex: 1 1 1%;
    line-height: 1.5;
    text-align: center;
    margin-top: $margin;
    > .label {
      font-size: 0.3rem;
      font-weight: bold;
    }
    > .desc {
      color: $gray6;
      @include text-line(2);
    }
  }
  > .worth {
    text-align: center;
    margin-top: $margin-big;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > .value {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 $margin;
      > .label {
        line-height: 1.2;
        font-size: 0.4rem;
        font-weight: bold;
        color: $color-main;
      }
      > .badge {
        order: 0;
        margin-right: $margin-small;
      }
      > .price {
        order: 1;
      }
    }
  }
}
</style>
